<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
      :style="{height: '12%'}"
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height: '88%'}"
      class="pt-2"
    >
      <v-col>
        <v-row
          no-gutters
        >
          <v-col
            sm="7"
            md="8"
            :cols="$vssWidth < 600 ? 7 : 6"
            class="d-flex align-center"
          >
            Tipo de segmento:
          </v-col>
          <v-col
            sm="5"
            md="4"
            :cols="$vssWidth < 600 ? 5 : 6"
            class="pt-2" 
          >
            <v-select
              dense
              outlined
              :items="['Ótica', 'Joalheria', 'Outros']"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 6"
            class="d-flex align-center"
          >
            Permite importar XML para CNPJ diferente da loja:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 6"
            class="pt-2"
          >
            <v-switch
              inset
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 6"
            class="d-flex align-center"
          >
            Gera numero do cliente automaticamente:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center pt-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Ao desabilitar este parâmetro o "número do cliente" deverá ser informado manualmente nas telas de cadastro de cliente.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  
                  class="pl-7 pb-2"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Ao desabilitar este parâmetro o "número do cliente" deverá ser informado manualmente nas telas de cadastro de cliente.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 6"
            class="d-flex align-center"
          >
            Valida carga horaria de colaborador:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="1" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-3"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center pt-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Ao habilitar este parâmetro o sistema irá validar a carga horária do colaborador, conforme o cadastro, nas telas de venda e pedido. <br>
                (Só deixará acessar se estiver no seu respectivo horário de serviço cadastrado no sistema)
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  :style="{paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : ''}"
                  class="pl-7 pb-2"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Ao habilitar este parâmetro o sistema irá validar a carga horária do colaborador, conforme o cadastro, nas telas de venda e pedido. <br>
                (Só deixará acessar se estiver no seu respectivo horário de serviço cadastrado no sistema)
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>