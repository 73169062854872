var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%","flat":""}},[_c('v-row',{style:({height: '12%'}),attrs:{"no-gutters":""}},[_c('v-card-title',{style:({borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'})},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-row',{staticClass:"pt-2",style:({height: '88%'}),attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"7","md":"8","cols":_vm.$vssWidth < 600 ? 7 : 6}},[_vm._v(" Tipo de segmento: ")]),_c('v-col',{staticClass:"pt-2",attrs:{"sm":"5","md":"4","cols":_vm.$vssWidth < 600 ? 5 : 6}},[_c('v-select',{attrs:{"dense":"","outlined":"","items":['Ótica', 'Joalheria', 'Outros'],"hide-details":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"10","md":"10","cols":_vm.$vssWidth < 600 ? 10 : 6}},[_vm._v(" Permite importar XML para CNPJ diferente da loja: ")]),_c('v-col',{staticClass:"pt-2",attrs:{"sm":"2","md":"2","cols":_vm.$vssWidth < 600 ? 2 : 6}},[_c('v-switch',{attrs:{"inset":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"10","md":"10","cols":_vm.$vssWidth < 600 ? 8 : 6}},[_vm._v(" Gera numero do cliente automaticamente: ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth < 600),expression:"$vssWidth < 600"}],staticClass:"pt-3",attrs:{"sm":"2","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"d-flex justify-center pt-1"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Ao desabilitar este parâmetro o \"número do cliente\" deverá ser informado manualmente nas telas de cadastro de cliente. ")])])],1),_c('v-col',{attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-switch',{attrs:{"inset":""}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 1 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth >= 600),expression:"$vssWidth >= 600"}],staticClass:"pl-7 pb-2"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Ao desabilitar este parâmetro o \"número do cliente\" deverá ser informado manualmente nas telas de cadastro de cliente. ")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"10","md":"10","cols":_vm.$vssWidth < 600 ? 8 : 6}},[_vm._v(" Valida carga horaria de colaborador: ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth < 600),expression:"$vssWidth < 600"}],staticClass:"pt-3",attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"d-flex justify-center pt-1"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Ao habilitar este parâmetro o sistema irá validar a carga horária do colaborador, conforme o cadastro, nas telas de venda e pedido. "),_c('br'),_vm._v(" (Só deixará acessar se estiver no seu respectivo horário de serviço cadastrado no sistema) ")])])],1),_c('v-col',{attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-switch',{attrs:{"inset":""}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 1 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth >= 600),expression:"$vssWidth >= 600"}],staticClass:"pl-7 pb-2",style:({paddingLeft: _vm.$vssWidth > 600 && _vm.$vssWidth < 1264 ? '20px' : ''})},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Ao habilitar este parâmetro o sistema irá validar a carga horária do colaborador, conforme o cadastro, nas telas de venda e pedido. "),_c('br'),_vm._v(" (Só deixará acessar se estiver no seu respectivo horário de serviço cadastrado no sistema) ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }